import { css } from 'styled-components';
import styles from './styles.json';

export default css`
  * {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    box-sizing: border-box;
  }
  html, body, div, span, applet, object, iframe,
  h1, h2, h3, h4, h5, h6, p, blockquote, pre,
  a, abbr, acronym, address, big, cite, code,
  del, dfn, em, img, ins, kbd, q, s, samp,
  small, strike, strong, sub, sup, tt, var,
  b, u, i, center,
  dl, dt, dd, ol, ul, li,
  fieldset, form, label, legend,
  table, caption, tbody, tfoot, thead, tr, th, td,
  article, aside, canvas, details, embed,
  figure, figcaption, footer, header, hgroup,
  menu, nav, output, ruby, section, summary,
  time, mark, audio, video {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    font: inherit;
    vertical-align: baseline;
  }
  article, aside, details, figcaption, figure,
  footer, header, hgroup, menu, nav, section {
    display: block;
  }
  body {
    line-height: 1.4;
    background-color: ${styles.colors.ash.base};
    // color: ${styles.colors.ash.base};
    color: red;
    font-family: ${styles.fontFamilies.system};
    font-size: ${styles.fontSizes.small};
  }
  .notch {
    padding-right: env(safe-area-inset-right);
    padding-left: env(safe-area-inset-left);
  }
  ol, ul {
    list-style: none;
    // margin-bottom: ${styles.spaces.large};
  }

  ol li, ul li {
    // margin-bottom: ${styles.spaces.small};
  }

  blockquote, q {
    quotes: none;
  }
  blockquote:before, blockquote:after,
  q:before, q:after {
    content: '';
    content: none;
  }
  table {
    border-collapse: collapse;
    border-spacing: 0;
  }
  img {
    width: 100%;
    height: auto;
    display: block;
  }
  img.rounded {
    border-radius: 25px;
    @media ${styles.breakpoints.tabletPortrait} {
      border-radius: 20px;
    }
    @media ${styles.breakpoints.mobile} {
      border-radius: 15px;
    }
    
    // box-shadow: 0px 8px 40px rgba(0,0,0,0.3);
  }

  img.borderdark {
    border: 2px solid rgba(255,255,255,0.075);
  }
  img.borderbright {
    border: 2px solid rgba(0,0,0,0.075);
  }

  a {
    text-decoration: none !important;
    transition: all .2s ease-in-out;
    font-weight: ${styles.fontWeights.semiBold};
    color: ${styles.colors.primary.base};
    &:hover {
      color: ${styles.colors.primary.light};
    }
  }
  p {
    margin-bottom: ${styles.spaces.large};
    line-height: 1.5;
    @media ${styles.breakpoints.tabletPortrait} {
      font-size: ${styles.fontSizes.small};
    }
    @media ${styles.breakpoints.mobile} {
      font-size: ${styles.fontSizes.xSmall};
    }
  }

small {
  font-size: 80%;
}
.caption {
  font-size: 80%;
  font-weight: bold;
}

.blog-post {}

.blog-post p {
  color: #222;
  line-height: 1.6;
  font-size: ${styles.fontSizes.small};
  @media ${styles.breakpoints.mobile} {
    font-size: ${styles.fontSizes.xSmall};
  }
}

.blog-post blockquote {
  line-height: 1.4;
  font-weight: ${styles.fontWeights.thin};
  color: ${styles.colors.ash.dark};
  background-color: #ffffff;
  margin-top: ${styles.fontSizes.xxLarge};
  margin-bottom: ${styles.spaces.xLarge};
  z-index: 10;
  & p {
    margin-bottom: 0;
    font-size: ${styles.fontSizes.xLarge};
    padding-left: ${styles.spaces.large};
    border-left: 4px solid ${styles.colors.ash.dark};
    font-style: oblique;
  }
  @media ${styles.breakpoints.tabletPortrait} {
    margin-top: ${styles.fontSizes.xLarge};
    margin-bottom: ${styles.spaces.large};
    p {
      font-size: ${styles.fontSizes.large};
    }
  }
  @media ${styles.breakpoints.mobile} {
    p {
      font-size: ${styles.fontSizes.medium};
      padding-left: ${styles.spaces.medium};
    }
  }
}

.blog-post div.note{
  line-height: 1.4;
  font-weight: ${styles.fontWeights.regular};
  color: ${styles.colors.ash.darker};
  background-color: ${styles.colors.primary.lightest};
  margin-top: ${styles.fontSizes.xxLarge};
  margin-bottom: ${styles.spaces.xLarge};
  z-index: 10;
  border: 1px solid ${styles.colors.primary.lighter};
  border-radius: 8px;
  width: 100%;
  font-size: ${styles.fontSizes.xSmall};
  padding: ${styles.spaces.large};
  & strong {
    color: ${styles.colors.ash.base};
  }
  @media ${styles.breakpoints.tabletPortrait} {
    font-size: ${styles.fontSizes.xxSmall};
    padding: ${styles.spaces.medium};
  }
  @media ${styles.breakpoints.mobile} {
    padding: ${styles.spaces.small};
  }
}


p.note {
  line-height: 1.4;
  font-weight: ${styles.fontWeights.regular};
  color: ${styles.colors.ash.darker};
  line-height: 1.4;
  font-weight: ${styles.fontWeights.regular};
  color: ${styles.colors.white};
  background-color: rgba(0,0,0,0.5);
  margin-top: ${styles.fontSizes.xxLarge};
  margin-bottom: ${styles.spaces.xLarge};
  z-index: 10;
  border: 2px solid rgba(0,0,0,0.25);
  border-radius: 8px;
  width: 100%;
  font-size: ${styles.fontSizes.xSmall};
  padding: ${styles.spaces.large};
  & strong {
    color: ${styles.colors.ash.base};
  }
  @media ${styles.breakpoints.tabletPortrait} {
    font-size: ${styles.fontSizes.xxSmall};
    padding: ${styles.spaces.medium};
  }
  @media ${styles.breakpoints.mobile} {
    padding: ${styles.spaces.small};
  }
}

  margin-top: ${styles.fontSizes.xxLarge};
  margin-bottom: ${styles.spaces.xLarge};
  z-index: 10;
  border: 1px solid ${styles.colors.primary.lighter};
  border-radius: 8px;
  width: 100%;
  font-size: ${styles.fontSizes.xSmall};
  padding: ${styles.spaces.large};
  & strong {
    color: ${styles.colors.ash.base};
  }
  &strong.white {
    color: ${styles.colors.white};
  }
  @media ${styles.breakpoints.tabletPortrait} {
    font-size: ${styles.fontSizes.xxSmall};
    padding: ${styles.spaces.medium};
  }
  @media ${styles.breakpoints.mobile} {
    padding: ${styles.spaces.small};
  }
}

  .blog-post h2 {
  font-family: AlteHassBold;
  font-size: ${styles.fontSizes.xxLarge};
  font-weight: 700;
  margin-top: 80px;
  margin-bottom: 40px;
  color: #222;
  @media ${styles.breakpoints.mobile} {
    font-size: ${styles.fontSizes.xLarge};
  }
 }

 .blog-post h3 {
  font-family: AlteHassBold;
  font-size: ${styles.fontSizes.large};
  font-weight: 700;
  margin-top: 80px;
  margin-bottom: 24px;
  color: #222;
  @media ${styles.breakpoints.mobile} {
    font-size: ${styles.fontSizes.small};
  }
 }

 .blog-post em {
  font-style: italic;
 }

 .blog-post ul, .blog-post  ol {
  padding-left: 2em;
  font-size: 28px;
  margin-bottom: 64px;
  font-size: ${styles.fontSizes.small};
  @media ${styles.breakpoints.mobile} {
    font-size: ${styles.fontSizes.xSmall};
  }
}

.blog-post ol li {
  list-style: decimal;
  list-style-position: inside;
  margin-top: 16px;
  margin-bottom: 32px;
  text-indent: -1em;
  padding-left: 0em;
  color: #222;
}

.blog-post ul li{
  list-style: disc;
  margin-top: 16px;
  margin-bottom: 24px;
  color: #222;
}

// ul li {
//   list-style: disc;
//   margin-top: 16px;
//   margin-bottom: 24px;
// }

 strong {
  font-weight: 700;
 }

 .blog-post strong {
  font-weight: 700;
  color: #222;
 }
 
 .blog-post a, p a {
  color: ${styles.colors.primary.base};;
  text-decoration: underline;
  &:hover {
    color: ${styles.colors.primary.light};
  }
 }

 .blog-post hr {
  margin-top: 80px;
  margin-bottom: 80px;
  margin-left: 0;
  border-top: 3px solid ${styles.colors.ash.base};
  border-right: 0;
  border-bottom: 0;
  border-left: 0;
  width: 120px;
 }

  .gatsby-resp-iframe-wrapper {
    display: block;
    margin-top: ${styles.spaces.large};
    margin-bottom: ${styles.spaces.large};
  }
  span.fullimage img, span.fullimage {
    display: block;
    width: 100vw;
    position: relative;
    left: 50%;
    right: 50%;
    margin-left: -50vw;
    margin-right: -50vw;
    margin-bottom: 80px;
  }

  span.cover {
    margin-top: -160px !important;
    & > img {
      margin-top: 0;
    }
  }
  .gatsby-resp-image-wrapper, .blog-post img {
    margin-top: ${styles.spaces.xLarge};
    margin-bottom: ${styles.spaces.xLarge};
    max-width: 100% !important;
    @media ${styles.breakpoints.mobile} {
      margin-top: ${styles.spaces.large};
      margin-bottom: ${styles.spaces.large};
    }
  }
  .gatsby-highlight {
    margin-top: ${styles.spaces.large};
    margin-bottom: ${styles.spaces.large};
    @media ${styles.breakpoints.mobile} {
      margin-top: ${styles.spaces.medium};
      margin-bottom: ${styles.spaces.medium};
    } 
  }
  twitterwidget {
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 64px !important;
    margin-top: 64px !important;
  }
`;
