import React from 'react';
import styled from 'styled-components';
import styles from '../styles/styles.json';
import PropTypes from 'prop-types';

const Title = styled.h1`
  font-family: ${styles.fontFamilies.system};
  font-size: ${styles.fontSizes.xxxLarge};
  font-weight: ${styles.fontWeights.bold};
  margin-top: ${styles.spaces.small};
  margin-bottom: ${styles.spaces.large};
  color: ${styles.colors.ash.base};
  @media ${styles.breakpoints.mobile} {
    font-size: ${styles.fontSizes.xLarge};
  }
`;

const Divider = styled.hr`
  margin-top: ${styles.spaces.large};
  margin-bottom: ${styles.spaces.medium};
  margin-left: 0;
  // border-top: 5px solid ${styles.colors.ash.base};
  border: 0;
  border-right: 0;
  border-bottom: 0;
  border-left: 0;
  width: 88px;
`;


class Title1Component extends React.Component {
  render() {
    return (
      <div>
        <Title>{this.props.title}</Title>
        {/* {this.props.title ? <Divider /> : null} */}
      </div>
    );
  }
}

Title1Component.propTypes = {
  title: PropTypes.string
};

export default Title1Component
