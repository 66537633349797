import React from 'react';
import styled from 'styled-components';
import styles from '../styles/styles.json';
import PropTypes from 'prop-types';
import Title2 from './Title2';
import Wrap from '../components/Wrap';

const Container = styled.div`
  background: ${props => props.bg ? props.bg : 'white'};
  margin: 0 auto;
  padding-top: ${props => props.fit ? '0' : styles.spaces.xxLarge};
  padding-bottom: ${props => props.fit ? '0' : styles.spaces.xxLarge};
  // padding-top: ${styles.spaces.xxLarge};
  // padding-bottom: ${styles.spaces.xxLarge};
  & h1 {
    color: ${props => props.dark ? 'white' : `rgba(0, 0, 0, 0.7)`};
  }
  & h2, h3, p, ol, ul {
    color: ${props => props.dark ? 'white' : `rgba(0, 0, 0, 0.7)`};
  }
  & p {
    color: ${props => props.dark ? 'white' : `rgba(0, 0, 0, 0.7)`};
  }
  & p.highlight {
    color: ${props => props.dark ? 'white' : `rgba(0, 0, 0, 0.7)`};
    font-family: ${styles.fontFamilies.system};
    font-weight: ${styles.fontWeights.regular};
    font-size: ${styles.fontSizes.xLarge};
    margin-top: ${styles.spaces.xLarge};
    margin-bottom: ${styles.spaces.xLarge};
    // border-left: 2px solid white;
    // padding-left: 32px;
    // width: 90%
  }
  @media ${styles.breakpoints.tabletPortrait} {
    padding-top: ${props => props.fit ? '0' : styles.spaces.xLarge};
    padding-bottom: ${props => props.fit ? '0' : styles.spaces.xLarge};
  }
  @media ${styles.breakpoints.mobile} {
    // padding-top: ${styles.spaces.large};
    // padding-bottom: ${styles.spaces.large};
    & p.highlight {
      font-size: ${styles.fontSizes.large};
    }
  }
`;

class SectionComponent extends React.Component {
  render() {
    return (
      <Container {...this.props}>
        <Wrap>
          {this.props.title ? <Title2 title={this.props.title} /> : null}
          {/* <Title2 title={this.props.title} /> */}
          {this.props.children}
        </Wrap>
      </Container>
    );
  }
}

SectionComponent.propTypes = {
  title: PropTypes.string
};

export default SectionComponent
