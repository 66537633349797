import React from 'react';
import styled from 'styled-components';
import styles from '../styles/styles.json';
import PropTypes from 'prop-types';
import Title1 from './Title1';

const Container = styled.div`
  background-color: ${props => props.bg ? props.bg : '#222'};
  background-image: url(${props => props.bgimage ? props.bgimage : null});
  background-repeat: no-repeat;
  background-size: cover;
  max-width: 100%;
  min-height: ${props => props.full ? '100vh' : 'auto'};
  @media ${styles.breakpoints.mobile} {
    min-height: 30vh;
  }

`;

const Intro = styled.div`
  padding-top: ${styles.spaces.xxxLarge};
  padding-bottom: ${styles.spaces.xxLarge};
  padding-right: ${styles.spaces.xLarge};
  padding-left: ${styles.spaces.xLarge};
  margin: 0 auto;
  max-width: 960px;
  font-size: ${styles.fontSizes.xLarge};
  font-weight: ${styles.fontWeights.regular};
  color: ${props => props.dark ? 'white' : `rgba(0, 0, 0, 0.7)`};
  & h1 {
    color: ${props => props.dark ? 'white' : `rgba(0, 0, 0, 0.7)`};
    font-size: ${styles.fontSizes.xxxxLarge};
  }
  & h2 {
    color: ${props => props.dark ? 'white' : `rgba(0, 0, 0, 0.7)`};
  }
  & img {
    margin-bottom: ${styles.spaces.xxLarge};
    @media ${styles.breakpoints.mobile} {
      margin-bottom: ${styles.spaces.xxLarge};
    }
  }
  & img.brand {
    max-height: 160px;
    opacity:  ${props => props.dark ? '1' : `0.7`};
    margin-bottom: ${styles.spaces.xxLarge};
  }
  & p {
    font-size: ${styles.fontSizes.large};
    font-weight: ${styles.fontWeights.Semibold};
  }
  & p.highlight {
    color: ${props => props.dark ? 'white' : `rgba(0, 0, 0, 0.7)`};
    font-family: ${styles.fontFamilies.system};
    font-weight: ${styles.fontWeights.semiBold};
    font-size: ${styles.fontSizes.xLarge};
    margin-top: ${styles.spaces.xLarge};
    margin-bottom: ${styles.spaces.xLarge};
  }
  & a {
    font-weight: ${styles.fontWeights.regular}
  }
  @media ${styles.breakpoints.tabletPortrait} {
    & h1 {
      font-size: ${styles.fontSizes.xxxLarge};
    }
    p {
      font-size: ${styles.fontSizes.large};
    }
    padding-right: ${styles.spaces.large};
    padding-left: ${styles.spaces.large};
  }
  @media ${styles.breakpoints.mobile} {
    & h1 {
      font-size: ${styles.fontSizes.xxLarge};
    }
    p {
      font-size: ${styles.fontSizes.medium}
    }
    font-size: ${styles.fontSizes.medium}
    // padding-top: ${styles.spaces.large};
    padding-right: ${styles.spaces.medium};
    padding-left: ${styles.spaces.medium};
  }
`;

class IntroComponent extends React.Component {
  render() {
    return (
      <div className="notch">
        <Container  {...this.props}>
          <Intro {...this.props}>
            {this.props.title ? <Title1 title={this.props.title} /> : null}
            {this.props.children}
          </Intro>
        </Container>
      </div>
    );
  }
}

IntroComponent.propTypes = {
  title: PropTypes.string
};

export default IntroComponent
