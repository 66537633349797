import React from 'react';
import styled from 'styled-components';
import styles from '../styles/styles.json';
import PropTypes from 'prop-types';
import { Link } from "gatsby"

const Container = styled.div`
  background: white;
  display: flex;
  justify-content: space-between;
  padding-top: ${styles.spaces.medium};
  padding-right: ${styles.spaces.medium};
  padding-bottom: ${styles.spaces.medium};
  padding-left: ${styles.spaces.medium};
  border-bottom: 0;
  border-top: 1px solid ${styles.colors.quiet.light};
  & a {
    font-size: ${styles.fontSizes.xxLarge};
    font-family: ${styles.fontFamilies.system};
    font-weight: ${styles.fontWeights.bold};
    color: ${styles.colors.ash.base};
    -o-transition: all 0.2s linear;
    -moz-transition: all 0.2s linear;
    -khtml-transition: all 0.2s linear;
    -webkit-transition: all 0.2s linear;
    -ms-transition: all 0.2s linear;
    transition: all 0.2s linear;
  }
  & a:hover {
    // color: ${styles.colors.primary.base};
  }
  & a.pro-icon-next {
    display: inline-block;
    vertical-align: middle;
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    -moz-osx-font-smoothing: grayscale;
    position: relative;
    padding: 10px 50px 10px 10px;
    -webkit-transition-duration: 0.1s;
    transition-duration: 0.1s;
    border: 0px solid transparent;
  }
  & a.pro-icon-next:before {
    content: "\f061";
    position: absolute;
    right: 10px;
    padding: 23px 0px;
    font-size: 30px;
    font-family: "Font Awesome 5 Free";
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
    -webkit-transition-duration: 0.1s;
    transition-duration: 0.1s;
    -webkit-transition-property: transform;
    transition-property: transform;
    -webkit-transition-timing-function: ease-out;
    transition-timing-function: ease-out;
  }
  & a.pro-icon-next:hover:before {
    -webkit-transform: translateX(4px);
    transform: translateX(4px);
  }
  & a.pro-icon-next:hover {
    -o-transition: all 0.2s linear;
    -moz-transition: all 0.2s linear;
    -khtml-transition: all 0.2s linear;
    -webkit-transition: all 0.2s linear;
    -ms-transition: all 0.2s linear;
    transition: all 0.2s linear;
  }

  & a.pro-icon-prev {
    display: inline-block;
    vertical-align: middle;
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    -moz-osx-font-smoothing: grayscale;
    position: relative;
    padding: 10px 10px 10px 50px;
    -webkit-transition-duration: 0.1s;
    transition-duration: 0.1s;
    border: 0px solid transparent;
  }
  & a.pro-icon-prev:before {
    content: "\f060";
    position: absolute;
    left: 0px;
    padding: 23px 10px;
    font-size: 30px;
    font-family: "Font Awesome 5 Free";
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
    -webkit-transition-duration: 0.1s;
    transition-duration: 0.1s;
    -webkit-transition-property: transform;
    transition-property: transform;
    -webkit-transition-timing-function: ease-out;
    transition-timing-function: ease-out;
  }
  & a.pro-icon-prev:hover:before, .pro-icon-prev:focus:before, .pro-icon-prev:active:before {
    -webkit-transform: translateX(-4px);
    transform: translateX(-4px);
  }
  & a.pro-icon-next:hover {
    -o-transition: all 0.2s linear;
    -moz-transition: all 0.2s linear;
    -khtml-transition: all 0.2s linear;
    -webkit-transition: all 0.2s linear;
    -ms-transition: all 0.2s linear;
    transition: all 0.2s linear;
  }
  @media ${styles.breakpoints.tabletPortrait} {
    padding-right: ${styles.spaces.small};
    padding-left: ${styles.spaces.small};
    a.pro-icon-next {
      font-size: ${styles.fontSizes.medium};
    }
    a.pro-icon-prev {
      font-size: ${styles.fontSizes.medium};
    }
    a.pro-icon-next:before {
      padding: 4px 10px 4px ;
      font-size: 20px;
    }
    a.pro-icon-prev:before {
      padding: 4px 20px 4px ;
      font-size: 20px;
    }
  }
  @media ${styles.breakpoints.mobile} {
    padding-right: 0;
    padding-left: 0;
    a.pro-icon-next {
      font-size: ${styles.fontSizes.xSmall};
    }
    a.pro-icon-prev {
      font-size: ${styles.fontSizes.xSmall};
    }
    a.pro-icon-next:before {
      padding: 2px 10px;
      font-size: 20px;
    }
    a.pro-icon-prev:before {
      padding: 2px 20px;
      font-size: 20px;
    }
  }

`;

const Pagination = ({mydata, currentproject}) => {
  let projectslenght = mydata.allJavascriptFrontmatter.edges.length - 1;
  let nextproject;
  let nextprojectUrl;
  let prevproject;
  let prevprojectUrl;
  if(currentproject != projectslenght){
    nextproject = mydata.allJavascriptFrontmatter.edges[currentproject + 1].node.frontmatter.title;
    nextprojectUrl = mydata.allJavascriptFrontmatter.edges[currentproject + 1].node.frontmatter.path;
  }
  if(currentproject != 0){
    prevproject = mydata.allJavascriptFrontmatter.edges[currentproject - 1].node.frontmatter.title;
    prevprojectUrl = mydata.allJavascriptFrontmatter.edges[currentproject - 1].node.frontmatter.path;
  }

  return (
    <Container>
      <div  className="notch">
      {currentproject != projectslenght
        ? <Link to={nextprojectUrl} className='pro-icon-prev'>{nextproject}</Link>
        : <div></div>
      }
        </div>
      <div  className="notch">
      {currentproject != 0
        ? <Link to={prevprojectUrl} className='pro-icon-next'>{prevproject}</Link>
        : <div></div>
      }
      </div>
    </Container>
  );    
};

export default Pagination