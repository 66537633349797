import React from 'react';
import styled from 'styled-components';
import styles from '../styles/styles.json';
import PropTypes from 'prop-types';

const Title = styled.h2`
  font-family: ${styles.fontFamilies.system};
  font-size: ${styles.fontSizes.xxLarge};
  font-weight: ${styles.fontWeights.bold};
  margin-top: ${styles.spaces.medium};
  margin-bottom: ${styles.spaces.medium};
  color: ${styles.colors.ash.base};
  @media ${styles.breakpoints.mobile} {
    font-size: ${styles.fontSizes.xLarge};
  }
`;

const Title2Component = ({ title }) => (
  <Title>{title}</Title>
)

Title2Component.propTypes = {
  title: PropTypes.string
};

export default Title2Component
