import { css } from 'styled-components';
import styles from '../styles.json';

import alteHaasBoldEot from './alteHaas/alte-haas-bold.eot';
import alteHaasBoldSvg from './alteHaas/alte-haas-bold.svg';
import alteHaasBoldTtf from './alteHaas/alte-haas-bold.ttf';
import alteHaasBoldWoff from './alteHaas/alte-haas-bold.woff';
import alteHaasBoldWoff2 from './alteHaas/alte-haas-bold.woff2';


export default css`

  @font-face {
    font-family: AlteHassBold;
    font-weight: ${styles.fontWeights.bold};
    font-style: normal;
    src: url('${alteHaasBoldEot}');
    src: url('${alteHaasBoldEot}?#iefix') format('embedded-opentype'),
        url('${alteHaasBoldTtf}') format('truetype'),
        url('${alteHaasBoldWoff}') format('woff'),
        url('${alteHaasBoldWoff2}') format('woff2'),
        url('${alteHaasBoldSvg}#alte_haas_groteskbold') format('svg');
  }


`;
