import React from 'react';
import styled from 'styled-components';
import styles from '../styles/styles.json';
import PropTypes from 'prop-types';
import Wrap from './Wrap';

const Container = styled.div`
  background: white;
`;

const FooterSocial = styled.ul`
  margin: 0 auto;
  max-width: 780px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-top: ${styles.spaces.xxLarge};
  padding-bottom: ${styles.spaces.medium};
  & p {
    margin: 0;
  }
  & a {
    color: ${styles.colors.ash.base};
    font-size: ${styles.fontSizes.xLarge};
    font-weight: 300;
    &:hover {
      color: ${styles.colors.primary.base};
    }
  }
  @media ${styles.breakpoints.mobile} {
    padding-top: ${styles.spaces.large};
    padding-bottom: ${styles.spaces.small};
  }
`;

const FooterInfo = styled.div`
  font-family: ${styles.fontFamilies.system};
  font-weight: ${styles.fontWeights.regular};
  font-size: ${styles.fontSizes.small};
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: ${styles.spaces.xxLarge};
  padding-bottom: ${styles.spaces.xxLarge};
  & a {
    color: ${styles.colors.ash.base};
    text-decoration: none;
    font-weight: ${styles.fontWeights.semiBold};
  }
  & a:hover {
    color: ${styles.colors.primary.base};
  }
  & p {
    margin: 0;
  }
  & .subtitle { 
    font-size: ${styles.fontSizes.xSmall};
    margin-top: ${styles.spaces.xSmall}
  }
  @media ${styles.breakpoints.mobile} {
    padding-top: ${styles.spaces.small};
    padding-bottom: ${styles.spaces.xxLarge};
    .subtitle {
      font-size: ${styles.fontSizes.xxSmall};
    }
  }
`;

const HeaderComponent = ({ siteTitle, links }) => (
  <div className="notch">
  <Container>
    <Wrap>
      <FooterSocial>
        <li className="social col-xs-4 text-center">
          <a href="https://www.linkedin.com/in/javieralvear"><i className="fab fa-linkedin"></i></a>
        </li>
        <li className="social col-xs-4 text-center">
          <a href="https://www.twitter.com/javieralvear_"><i className="fab fa-twitter"></i></a>
        </li>
        <li className="social col-xs-4 text-center">
          <a href="https://www.dribbble.com/javieralvear"><i className="fab fa-dribbble"></i></a>
        </li>
      </FooterSocial>
      <FooterInfo>
        <p>© Javier Alvear</p>
        <p className="subtitle"><a href="mailto:hello@javieralvear.com">Send an email</a> or <a href="http://twitter.com/javieralvear_">tweet me</a></p>
      </FooterInfo>
    </Wrap>
  </Container>
  </div>
)

HeaderComponent.propTypes = {
  name: PropTypes.string,
  linkClass: PropTypes.string,
};

export default HeaderComponent
