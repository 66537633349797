import React from 'react';
import styled from 'styled-components';
import styles from '../styles/styles.json';

const Wrap = styled.div`
  margin: 0 auto;
  max-width: 960px;
  padding-right: ${styles.spaces.xLarge};
  padding-left: ${styles.spaces.xLarge};
  @media ${styles.breakpoints.tabletPortrait} {
    padding-right: ${styles.spaces.large};
    padding-left: ${styles.spaces.large};
  }
  @media ${styles.breakpoints.mobile} {
    padding-right: ${styles.spaces.medium};
    padding-left: ${styles.spaces.medium};
  }
`;


class WrapComponent extends React.Component {
  render() {
    return (
      <Wrap {...this.props}>
          {this.props.children}
        </Wrap>
    );
  }
}


export default WrapComponent
